import React from "react";
import { Route } from "react-router-dom";

import Routes from "../shared/components/routes";
import RouteNotFound from "../shared/components/routes/RouteNotFound";
import { Action } from "../shared/components/store/Router";
import { useResourceRouter } from "../shared/hooks/useResourceRouter";
import { ResourceType } from "../types/serializers";

import StatusHandler from "./components/statusHandler";
import BlogIndexPage from "./pages/blog/BlogIndexPage";
import BlogShowPage from "./pages/blog/BlogShowPage";
import BlogAuthorIndexPage from "./pages/blogAuthors/BlogAuthorIndexPage";
import BlogAuthorShowPage from "./pages/blogAuthors/BlogAuthorShowPage";
import ChatConversationShowPage from "./pages/chatConversations/ChatConversationShowPage";
import PillarPageIndexPage from "./pages/pillarPages/PillarPageIndexPage";
import PillarPageShowPage from "./pages/pillarPages/PillarPageShowPage";
import AccountPage from "./pages/static/AccountPage";
import DashboardPage from "./pages/static/DashboardPage";
import GptNewPage from "./pages/static/GptNewPage";
import HomePage from "./pages/static/HomePage";
import SignInPage from "./pages/static/SignInPage";
import UpgradeBrowserPage from "./pages/static/UpgradeBrowserPage";

const MainRoutes = () => {
  const resourceRoute = useResourceRouter(StatusHandler);

  return (
    <Routes>
      <Route index element={<HomePage />} />
      {resourceRoute(
        ResourceType.BlogAuthor,
        Action.Index,
        BlogAuthorIndexPage
      )}
      {resourceRoute(ResourceType.BlogAuthor, Action.Show, BlogAuthorShowPage)}
      {resourceRoute(ResourceType.Blog, Action.Index, BlogIndexPage)}
      {resourceRoute(ResourceType.Blog, Action.Show, BlogShowPage)}
      {resourceRoute(
        ResourceType.ChatConversation,
        Action.Show,
        ChatConversationShowPage
      )}
      {resourceRoute(
        ResourceType.PillarPage,
        Action.Index,
        PillarPageIndexPage
      )}
      {resourceRoute(ResourceType.PillarPage, Action.Show, PillarPageShowPage)}

      <Route element={<GptNewPage />} path="gpt" />
      <Route element={<SignInPage />} path="users/sign_in" />
      <Route element={<AccountPage />} path="account" />
      <Route element={<DashboardPage />} path="dashboard" />
      <Route element={<RouteNotFound />} path="*" />
      <Route element={<UpgradeBrowserPage />} path="upgrade" />
    </Routes>
  );
};

export default MainRoutes;
