import React from "react";
import { useTranslation } from "react-i18next";

import Main from "../../../shared/components/main/Main";
import MainBody from "../../../shared/components/main/MainBody";
import MainHeader from "../../../shared/components/main/MainHeader";
import Breadcrumbs from "../../components/breadcrumbs";
import PillarPageAccordion from "../../components/pillarPages/PillarPageAccordion";
import DefaultLayout from "../../layouts/DefaultLayout";

const GRID_COLUMNS = {
  base: "repeat(1, 1fr)",
  md: "repeat(2, 1fr)",
  lg: "repeat(3, 1fr)",
};

const PillarPageIndexPage = () => {
  const { t } = useTranslation();

  return (
    <Main>
      <DefaultLayout>
        <Breadcrumbs
          items={[{ href: "/onderwerpen", label: t("main:breadcrumbs.home") }]}
        />
        <MainHeader title={t("main:pillarPages.showAll")} />
        <MainBody>
          <PillarPageAccordion gridColumns={GRID_COLUMNS} />
        </MainBody>
      </DefaultLayout>
    </Main>
  );
};

export default PillarPageIndexPage;
