import {
  Box,
  Button,
  HStack,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useContext, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

import { AppContext } from "../../../shared/components/appContext/AppContextProvider";
import Main from "../../../shared/components/main/Main";
import useCurrentUser from "../../../shared/hooks/useCurrentUser";
import useResource from "../../../shared/hooks/useResource";
import {
  ChatConversationResource,
  ChatConversationStatus,
} from "../../../types/serializers";
import ChatInteraction from "../../components/Chat/components/ChatInteraction";
import ChatNotepad from "../../components/Chat/components/ChatNotepad";
import { ChatWindow } from "../../components/Chat/components/ChatWindow";
import FeedbackBox from "../../components/Chat/components/FeedbackBox";
import useAppearanceSubscription from "../../components/Chat/hooks/useAppearanceSubscription";
import useChatParticipation from "../../components/Chat/hooks/useChatParticipation";
import userName from "../../components/Chat/lib/userName";
import UserQuestionsLink from "../../components/chatViewer/UserQuestionsLink";
import useHideFooter from "../../components/footer/hooks/useHideFooter";

const ChatConversationShowPage = ({
  resource,
}: {
  resource: ChatConversationResource;
}) => {
  const { previousLocation } = useContext(AppContext);
  const [currentUser] = useCurrentUser();
  useAppearanceSubscription();
  useHideFooter();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const creator = useResource(resource.relationships.user.data);
  const showFeedbackBox =
    [
      ChatConversationStatus.HelpedQuery,
      ChatConversationStatus.Closed,
    ].includes(resource.attributes.status) && currentUser?.attributes.isGuide;
  const isParticipant = !!useChatParticipation(
    resource,
    currentUser?.attributes.id
  );
  const [showNotepadMobile, setShowNotepadMobile] = useState(false);
  const showNotepadDesktop =
    currentUser?.attributes.isGuide &&
    isParticipant &&
    resource.attributes.status === ChatConversationStatus.Active;
  const showNotepad = isMobile ? showNotepadMobile : showNotepadDesktop;

  return (
    <Main>
      <VStack
        background="white"
        bottom="0em"
        flexGrow="1"
        left="0"
        marginX="auto"
        maxWidth="container.xl"
        minWidth={0}
        padding="1em"
        position="fixed"
        right="0em"
        top={{ base: "0", md: "4em" }}
        zIndex={{ base: "elevatedChat", lg: "unset" }}
      >
        <Box width="100%">
          <Button
            as={Link}
            color="primary.darkActive"
            leftIcon={<FiChevronLeft />}
            to={
              previousLocation ??
              (currentUser?.attributes.isGuide ? "/dashboard" : "/account")
            }
            variant="link"
          >
            {t("main:chat.show.allQuestions")}
          </Button>
        </Box>
        <HStack justifyContent="space-between" width="100%">
          <Box>
            <Box fontWeight="bold" noOfLines={1}>
              {userName(creator)}
            </Box>
            <UserQuestionsLink chatConversation={resource} />
          </Box>
          <Box>
            <ChatInteraction
              chatConversation={resource}
              setShowNotepad={isMobile ? setShowNotepadMobile : undefined}
              showNotepad={showNotepad}
            />
          </Box>
        </HStack>
        <HStack
          alignItems="flex-start"
          flexDirection={{ base: "column-reverse", md: "row" }}
          flexGrow={1}
          gap="1em"
          overflow="auto"
          width="100%"
        >
          {showNotepad && (
            <Box
              bottom="0em"
              height="100%"
              maxWidth={{ base: "100%", lg: "25em" }}
              padding={{ base: "1em", lg: "0" }}
              position={{ base: "fixed", lg: "unset" }}
              right="0em"
              top={{ base: "0", md: "unset" }}
              width={{ base: "25em", lg: "100%" }}
              zIndex={{
                base: showNotepad ? "elevatedNotepad" : "unset",
                lg: "unset",
              }}
            >
              <ChatNotepad
                closeNotepad={
                  isMobile ? () => setShowNotepadMobile(false) : undefined
                }
              />
            </Box>
          )}
          <VStack height="100%" width="100%">
            {showFeedbackBox && (
              <Box
                display={{ base: "block", md: "none" }}
                flexShrink={0}
                width="100%"
              >
                <FeedbackBox chatConversation={resource} />
              </Box>
            )}
            <ChatWindow
              chatConversation={resource}
              flexGrow="1"
              height="100%"
            />
          </VStack>
          {showFeedbackBox && (
            <Box display={{ base: "none", md: "block" }} minWidth="20em">
              <FeedbackBox chatConversation={resource} />
            </Box>
          )}
        </HStack>
      </VStack>
    </Main>
  );
};

export default ChatConversationShowPage;
