import React from "react";
import { useTranslation } from "react-i18next";

import Main from "../../../shared/components/main/Main";
import MainHeader from "../../../shared/components/main/MainHeader";
import { ResourceStatus } from "../../../shared/components/store/Store";
import DefaultLayout from "../../layouts/DefaultLayout";
import Breadcrumbs from "../breadcrumbs";

const useTitle = (status: ResourceStatus) => {
  const { t } = useTranslation();

  switch (status) {
    case ResourceStatus.Missing:
    case ResourceStatus.Fetching:
      return t("shared:status.loading");
    case ResourceStatus.NotFound:
      return t("shared:status.notFound");
    case ResourceStatus.Error:
      return t("shared:status.error");
    case ResourceStatus.Forbidden:
      return t("shared:status.forbidden");
    default:
      throw new Error(`Unhandled status: ${status}`);
  }
};

const AdminStatusHandler = ({ status }: { status: ResourceStatus }) => {
  const { t } = useTranslation();
  const title = useTitle(status);

  return (
    <Main>
      <DefaultLayout>
        <Breadcrumbs
          items={[{ href: "/onderwerpen", label: t("main:breadcrumbs.home") }]}
        />
        <MainHeader title={title} />
      </DefaultLayout>
    </Main>
  );
};

export default AdminStatusHandler;
